import { render, staticRenderFns } from "./courseIntro.vue?vue&type=template&id=42049836&scoped=true&"
var script = {}
import style0 from "./courseIntro.vue?vue&type=style&index=0&id=42049836&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42049836",
  null
  
)

export default component.exports